@import url('https://fonts.googleapis.com/css2?family=Oranienbaum&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=Shadows+Into+Light&display=swap');
/* @import-normalize; */

:root {
  --font-family: 'Poppins', sans-serif;

  --color-1: rgba(17, 16, 22, 1);
  --color-2: rgba(24, 20, 26, 1);
  --color-3: rgba(100, 100, 110, 1);
  --color-4: rgba(131, 125, 133, 1);
  --color-5: rgba(125, 33, 67, 1);
--hover-1: rgba(255,255,255,.5);

  --gradient-1: linear-gradient(-45deg, #ee7752, #e73c7e);
  --gradient-1-1: linear-gradient(-45deg, #c56143, #972853);
  --gradient-2: linear-gradient(60deg, #fc466b, #6a82fb);
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background:var(--color-1); */
  color: #fff;

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 0% 8%,
    rgba(0, 0, 0, 1) 1%,
    rgba(37, 4, 40, 1) 82%,
    rgba(14, 7, 16, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 0% 8%,
    rgba(0, 0, 0, 1) 1%,
    rgba(37, 4, 40, 1) 82%,
    rgba(14, 7, 16, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 0% 8%,
    rgba(0, 0, 0, 1) 1%,
    rgba(37, 4, 40, 1) 82%,
    rgba(14, 7, 16, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 0% 8%,
    rgba(0, 0, 0, 1) 1%,
    rgba(37, 4, 40, 1) 82%,
    rgba(14, 7, 16, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 0% 8%,
    rgba(0, 0, 0, 1) 1%,
    rgba(37, 4, 40, 1) 82%,
    rgba(14, 7, 16, 1) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
